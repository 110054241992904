<template>
  <div
    class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6"
  >
    <div class="flex h-16 shrink-0 items-center mt-8 mb-4">
      <img
        class="h-auto max-h-16"
        src="@/assets/images/logos/logo-comeen-workplace.svg"
        alt="Comeen Workplace logo"
      />
    </div>
    <nav class="flex flex-1 flex-col">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" class="-mx-2 space-y-1">
            <li v-for="item in navigation" :key="item.name">
              <SidebarItem
                v-if="!item.children"
                :name="item.name"
                :route="item.routeName"
                :icon="item.icon"
                :class="{ 'menu-link-active': currentItem?.routeName === item?.routeName }"
              />
              <SidebarGroup
                v-else
                :name="item.name"
                :icon="item.icon"
                :default-open="hasActiveChild(item)"
              >
                <li v-for="subItem in item.children" :key="subItem.name">
                  <SidebarItem
                    :name="subItem.name"
                    :route="subItem.routeName"
                    :icon="subItem.icon"
                    :class="{ 'menu-link-active': currentItem?.routeName === subItem?.routeName }"
                  />
                </li>
              </SidebarGroup>
            </li>
          </ul>
        </li>
        <li class="-mx-6 mt-auto">
          <HelpcenterLink />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import {
  faBell,
  faBookmark,
  faBriefcase,
  faClock,
  faCoffee,
  faCube,
  faDatabase,
  faDesktop,
  faGear,
  faHouse,
  faMap,
  faMicrochip,
  faPlanetMoon,
  faUser,
  faUsers,
  faChartMixed,
  faMugHot,
} from '@fortawesome/pro-regular-svg-icons'

import SidebarItem from './SidebarItem.vue'
import SidebarGroup from './SidebarGroup.vue'
import HelpcenterLink from './SidebarHelpcenterLink.vue'

const auth = useAuth()

const { t } = useI18n()

const route = useRoute()
const { host } = useRequestURL()

const hasActiveChild = (group) =>
  group.children.some((subItem) => route.name?.startsWith(subItem.routeName))

const navigation = computed(() => {
  const navItems = []

  navItems.push({
    name: 'ui.common.home',
    routeName: 'index',
    icon: faHouse,
  })

  const userValue = auth.user.value

  if (!userValue.isLoggedIn) {
    return navItems
  }

  const currentSpace = host.split('.')[0]
  const onAdmin = userValue.isSuperAdmin && currentSpace === 'admin'

  if (onAdmin) {
    navItems.push({
      name: 'resources.spaces.name',
      routeName: 'spaces',
      icon: faCube,
    })

    navItems.push({
      name: 'resources.admins.name',
      icon: faUser,
      routeName: 'admins',
    })

    navItems.push({ 
      name: 'resources.settings.name',
      icon: faGear,
      routeName: 'settings',
    })
    return navItems
  }

  navItems.push({
    name: 'resources.visitors.name',
    icon: faBriefcase,
    routeName: 'userspaces-visitors',
  })

  navItems.push({
    name: 'resources.buildings.name',
    routeName: 'buildings',
    icon: faMap,
  })

  navItems.push({
    name: 'pages.announcements.home.title',
    routeName: 'announcements',
    icon: faBell,
  })

  if (auth.user.value.isAny('superadmin', 'owner', 'admin')) {
    navItems.push({
      name: 'resources.meeting_rooms.name',
      routeName: 'meeting_rooms',
      icon: faCoffee,
    })

    navItems.push({
      name: 'resources.users.name',
      routeName: 'users',
      icon: faUser,
      children: [
        {
          name: 'ui.common.actions.list',
          icon: faUser,
          routeName: 'users',
        },
        {
          name: 'resources.user_groups.name',
          icon: faUsers,
          routeName: 'users-groups',
        },
      ],
    })
  }

  navItems.push({
    name: 'resources.insights.name',
    routeName: 'insights',
    icon: faChartMixed,
    children: [
      {
        name: 'resources.presences.name',
        icon: faClock,
        routeName: 'insights-presences',
      },
      // {
      //   name: 'resources.meeting_rooms.name',
      //   icon: faMugHot,
      //   routeName: 'insights-rooms',
      // },
    ],
  })

  navItems.push({
    name: 'resources.settings.name',
    routeName: 'settings',
    icon: faGear,
    children: [
      {
        name: 'resources.spaces.name',
        icon: faPlanetMoon,
        routeName: 'settings',
      },
      {
        name: 'pages.integrations.title',
        icon: faMicrochip,
        routeName: 'settings-integrations',
      },
      {
        name: 'resources.presences.name',
        icon: faClock,
        routeName: 'settings-attendance',
      },
      {
        name: 'resources.bookables.name',
        icon: faBookmark,
        routeName: 'settings-bookables',
      },
      {
        name: 'resources.desks.name',
        icon: faDesktop,
        routeName: 'settings-desks',
      },
      {
        name: 'resources.meeting_rooms.name',
        icon: faCoffee,
        routeName: 'settings-rooms',
      },
      {
        name: 'pages.data_export.title',
        icon: faDatabase,
        routeName: 'settings-data',
      },
      {
        name: 'pages.kiosks.title',
        icon: faBriefcase,
        routeName: 'settings-kiosks',
      },
    ],
  })

  return navItems
})

const currentItem = computed(() => {
  let current = null;
  
  const checkItem = (item) => {
    if (route.name?.startsWith(item.routeName)) {
      if (!current || item.routeName.length > current.routeName.length) {
        current = item;
      }
    }
    
    if (item.children) {
      item.children.forEach(checkItem);
    }
  };
  
  navigation.value.forEach(checkItem);
  
  return current;
})
</script>

<style lang="postcss" scoped>
.menu-link-active {
  @apply bg-gray-100;
}
</style>
